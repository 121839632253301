<template>
   <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
         <div class="col-12">
            <div class="page-title-box">
               <h4 class="page-title">Role Management</h4>
            </div>
         </div>
      </div>
      <!-- end page title -->
      <div class="row">
         <div class="col-lg-12">
            <div class="card">
               <div class="card-body">
                  <form class="parsley-examples">
                     <div class="row">
                        <div class="col-md-6">
                           <div class="mb-3">
                              <label for="name" class="form-label">Name</label>
                              <input
                                 type="text"
                                 id="name"
                                 name="name"
                                 class="form-control"
                                 placeholder="Name"
                                 v-model="role.name"
                                 :class="{
                                    'p-invalid':
                                       v$.role.name.$error || errorFor('name'),
                                 }"
                                 autocomplete="off"
                              />
                              <v-errors
                                 :serverErrors="errorFor('name')"
                                 :vuelidateErrors="{
                                    errors: v$.role.name.$errors,
                                    value: 'Name',
                                 }"
                              ></v-errors>
                           </div>
                        </div>
                        <!-- end col -->

                        <div class="col-md-6">
                           <h4 class="header-title mt-5 mt-sm-0">
                              Assign Permissions To Role
                           </h4>
                           <div class="mt-2">
                              <div
                                 class="form-check"
                                 v-for="(permission, index) in permissions"
                                 :key="`permission${index}`"
                              >
                                 <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :id="`permission-${index}`"
                                    :value="permission"
                                    v-model="role.permissions"
                                 />
                                 <label
                                    class="form-check-label"
                                    :for="`permission-${index}`"
                                    >{{ permission }}</label
                                 >
                              </div>
                              <v-errors
                                 :serverErrors="errorFor('permissions')"
                                 :vuelidateErrors="{
                                    errors: v$.role.permissions.$errors,
                                    value: 'Permission',
                                 }"
                              ></v-errors>
                           </div>
                        </div>
                        <!-- end col -->
                     </div>
                     <!-- end row -->
                     <div class="row">
                        <div class="col-12">
                           <div class="text-center mt-3 mb-3">
                              <router-link :to="{ name: 'roles' }">
                                 <button
                                    type="button"
                                    class="btn w-sm btn-light"
                                 >
                                    Cancel
                                 </button>
                              </router-link>
                              <button
                                 type="button"
                                 class="
                                    btn
                                    w-sm
                                    btn-success
                                    waves-effect waves-light
                                 "
                                 @click="
                                    currentRouteName === 'role-update'
                                       ? updateRole()
                                       : createRole()
                                 "
                              >
                                 Save
                              </button>
                           </div>
                        </div>
                        <!-- end col -->
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
   setup() {
      const toast = useToast();
      return { v$: useVuelidate(), toast };
   },
   mixins: [validationErrors],
   data() {
      return {
         role: {
            name: "",
            permissions: [],
         },
         permissions: [],
         baseUrl: process.env.VUE_APP_BASE_URL,
      };
   },
   validations() {
      return {
         role: {
            name: { required },
            permissions: { required },
         },
      };
   },
   methods: {
      getRoleById() {
         axios
            .get(`${this.baseUrl}admin/v1/roles/${this.$route.params.id}`)
            .then((response) => {
               this.permissions = response.data.data.permissions;
               this.role.name = response.data.data.role.name;
               this.role.permissions = response.data.data.role.permissions.map(
                  (permission) => permission.name
               );
            })
            .catch(() => {
               this.$router.push({ name: "roles" });
               this.toast.error("Not found role!");
            });
      },
      getPermissions() {
         axios
            .get(`${this.baseUrl}admin/v1/permissions`)
            .then((response) => {
               this.permissions = response.data.data.map(
                  (permission) => permission.name
               );
            })
            .catch(() => {
               this.$router.push({ name: "roles" });
               this.toast.error("Not found role!");
            });
      },
      async createRole() {
         const isFormCorrect = await this.v$.$validate();
         if (!isFormCorrect) return;

         axios
            .post(`${this.baseUrl}admin/v1/roles`, this.role)
            .then(() => {
               this.$router.push({ name: "roles" });
               this.toast.success("Successfully role created!");
            })
            .catch((error) => {
               this.errors = error.response.data.errors;
               this.toast.error("Something went wrong!");
            });
      },
      async updateRole() {
         const isFormCorrect = await this.v$.$validate();
         if (!isFormCorrect) return;

         axios
            .put(
               `${this.baseUrl}admin/v1/roles/${this.$route.params.id}`,
               this.role
            )
            .then(() => {
               this.$router.push({ name: "roles" });
               this.toast.success("Successfully role updated!");
            })
            .catch((error) => {
               this.errors = error.response.data.errors;
               this.toast.error("Something went wrong!");
            });
      },
   },
   created() {
      if (this.currentRouteName === "role-update") {
         this.getRoleById();
      } else {
         this.getPermissions();
      }
   },
   computed: {
      currentRouteName() {
         return this.$route.name;
      },
   },
};
</script>